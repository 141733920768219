<template>
  <b-table
    striped
    hover
    responsive
    small
    show-empty
    class="position-relative items-center"
    :per-page="perPage"
    :items="items"
    :fields="fields"
    :sort-desc="false"
    @sort-changed="onSortChange"
  >
    <template #cell(index)="data">
      {{ perPage * (currentPage - 1) + (data.index + 1) }}
    </template>
    <template #empty="scope">
      <div class="p-2 text-center">
        <img
          src="/box.png"
          alt="empty"
          height="40px"
        >
        {{
          scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
        }}
      </div>
    </template>
    <template #cell(detail)="data">
      <div class="text-nowrap">
        <b-button
          title="รายละเอียด"
          variant="gradient-info"
          size="sm"
          @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username } })"
        >
          <i class="fas fa-address-card" />
        </b-button>
      </div>
    </template>
    <template #cell(main_wallet)="data">
      <span class="text-warning">{{ data.item.main_wallet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(kr_wallet)="data">
      <span class="text-warning">{{ data.item.kr_wallet.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(chip)="data">
      <span class="text-info">{{ data.item.chip.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(total_deposit)="data">
      <span class="text-success">{{ data.item.total_deposit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(total_deposit_kr)="data">
      <span class="text-success">{{ data.item.total_deposit_kr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(withdraw)="data">
      <span class="text-danger">{{ data.item.withdraw.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(withdraw_kr)="data">
      <span class="text-danger">{{ data.item.withdraw_kr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(bonus)="data">
      <span class="text-primary">{{ data.item.bonus.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(bonus_kr)="data">
      <span class="text-primary">{{ data.item.bonus_kr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
    </template>
    <template #cell(tel)="data">
      {{ data.item.tel }}
    </template>

    <template #cell(name)="data">
      {{ data.item.name }}
      {{ data.item.surname }}
    </template>

    <template #cell(user_pass)="data">
      <button
        :id="data"
        class="btn btn-sm btn-warning"
        @click="$emit('getCreditBalance', data.item.username)"
      >
        {{ data.item.username }}
      </button>
    </template>

    <template #cell(bankname)="data">
      <div class="text-center">
        <img
          v-if="data.item.bank_img"
          :src="`/bankIcon/${data.item.bank_img}`"
          alt="bankIcon"
          height="25"
          width="25"
          class="rounded-circle"
        >
        <br>
        <small>{{ data.item.acc_no }}</small>
      </div>
    </template>

    <template #cell(actions)="data">
      <b-dropdown
        id="dropdown-3"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        text="Success"
        variant="success"
      >
        <template #button-content>
          <i class="far fa-edit mr-50" />
        </template>
        <b-dropdown-item
          @click="
            $router.push({
              name: 'history-turnover',
              params: { id: data.item.username },
            })
          "
        ><i class="far fa-file-spreadsheet mr-1 text-info" /> Turn Over</b-dropdown-item>
        <b-dropdown-item
          @click="
            $router.push({
              name: 'Deposit-Withdraw',
              params: { id: data.item.username },
            })
          "
        ><i class="far fa-edit mr-1 text-warning" /> แก้ไข</b-dropdown-item>
        <b-dropdown-item
          v-if="UserData.vip == 1"
          @click="$emit('selectUser', data.item)"
        ><i class="fas fa-diamond mr-1 text-primary" /> เพิ่ม/ตัด เพชร
        </b-dropdown-item>
        <b-dropdown-item
          v-if="UserData.role === 'super admin'"
          @click="deleteuser(data.item)"
        ><i class="fas fa-trash mr-1 text-danger" /> ลบยูสเซอร์
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import {
  BTable, VBToggle, VBTooltip, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    BTable, VBToggle, VBTooltip, BButton, BDropdown, BDropdownItem,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: ['items', 'perPage'],
  emits: ['sorted', 'getCreditBalance', 'selectUser', 'getUsers'],
  data: () => ({
    fields: [
      { key: 'detail', label: 'รายละเอียด' },
      { key: 'main_wallet', label: 'ยอดเงิน (THB)', sortable: true },
      { key: 'kr_wallet', label: 'ยอดเงิน (KRW)', sortable: true },
      { key: 'chip', label: 'เพชร', sortable: true },
      { key: 'tel', label: 'เบอร์โทร' },
      { key: 'name', label: 'ชื่อ-นามสกุล' },
      { key: 'deposit_count', label: 'ครั้งที่ฝาก (THB)', sortable: true },
      { key: 'total_deposit', label: 'ฝากรวม (THB)', sortable: true },
      {
        key: 'deplast_date',
        label: 'ฝากล่าสุดเมื่อ (THB)',
        sortable: true,
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'deposit_count_kr', label: 'ครั้งที่ฝาก (KRW)', sortable: true },
      { key: 'total_deposit_kr', label: 'ฝากรวม (KRW)', sortable: true },
      {
        key: 'deplast_date_kr',
        label: 'ฝากล่าสุดเมื่อ (KRW)',
        sortable: true,
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'bonus', label: 'โบนัส (THB)' },
      { key: 'bonus_kr', label: 'โบนัส (KRW)' },
      { key: 'withdraw_count', label: 'ครั้งที่ถอน (THB)', sortable: true },
      { key: 'withdraw', label: 'ถอนรวม (THB)', sortable: true },
      { key: 'withdraw_count_kr', label: 'ครั้งที่ถอน (KRW)', sortable: true },
      { key: 'withdraw_kr', label: 'ถอนรวม (KRW)', sortable: true },
      { key: 'invited_by_name', label: 'ผู้แนะนำ', sortable: true },
      { key: 'bankname', label: 'ธนาคาร', thClass: 'text-center' },
      {
        key: 'created_at',
        label: 'สมัครเมื่อ',
        sortable: true,
        formatter: value => (value
          ? moment(value)
            .tz('Asia/Bangkok')
            .add('hours', 7)
            .format('DD-MM-YYYY HH:mm')
          : ''),
      },
      { key: 'user_pass', label: 'User / Password[พนัน]' },
      { key: 'actions', label: 'จัดการ' },
    ],
    UserData: JSON.parse(localStorage.getItem('userData')),
  }),
  methods: {
    onSortChange(ctx) {
      // console.log(ctx)
      const { sortBy, sortDesc } = ctx

      this.$emit('sorted', { sortBy, sortDesc })
    },
    deleteuser(data) {
      this.$swal({
        title: 'ยืนยันที่จะลบยูสเซอร์ ?',
        text: `ยูส : ${data.username} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/users/delete/${data.id}`)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.Success('ลบยูสเซอร์สำเร็จ')
              this.$emit('sorted')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}

</script>
